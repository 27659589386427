<template>
    <div class="home">
        <el-radio-group
            v-model="orgId"
            size="large"
            @change="selectOrgChange"
            style="margin: 10px"
        >
            <el-radio-button
                :label="item.id"
                v-for="(item, index) in userInfo?.organizations"
                :key="index"
                >{{ item.title }}</el-radio-button
            >
        </el-radio-group>

        <el-row class="home-mini-create" v-if="!isAwaitMini && isCreateMini">
            <el-row :gutter="12">
                <el-col :span="8">
                    <el-card shadow="hover" class="card_bg">
                        <el-row>
                            <el-col class="align_center title"
                                >已拥有微信小程序</el-col
                            >
                            <el-col class="align_center des"
                                >授权已有的微信小程序，可快速生成小程序</el-col
                            >
                            <el-col class="align_center"
                                ><el-button type="primary" class="btn"
                                    >授权微信小程序</el-button
                                ></el-col
                            >
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="hover" class="card_bg">
                        <el-row>
                            <el-col class="align_center title"
                                >还没有微信小程序</el-col
                            >
                            <el-col class="align_center des"
                                >已有微信公众号，可复用资质，快速创建小程序</el-col
                            >
                            <el-col class="align_center"
                                ><el-button
                                    type="primary"
                                    class="btn"
                                    @click="handleCreate"
                                    >快速注册</el-button
                                ></el-col
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-row>
        <div class="home-mini-content" v-if="orgId && isAuth">
            <div class="home-mini-content-select">
                <el-radio-group v-model="selected" class="ml-4">
                    <el-radio label="1" size="large">海报</el-radio>
                    <!-- <el-radio label="2" size="large">h5</el-radio>
                    <el-radio label="3" size="large">小程序</el-radio> -->
                </el-radio-group>
            </div>
            <el-row class="home-mini-content-detail">
                <el-col
                    :span="10"
                    class="home-mini-content-detail-upload"
                    :style="
                        selectedSize == 1
                            ? `width: 275px; height: 567px; flex: 0 0 275px;`
                            : `width: 300px; height: 300px; flex: 0 0 300px;`
                    "
                >
                    <el-image
                        v-if="imageUrl"
                        :src="imageUrl"
                        class="avatar"
                        :zoom-rate="1.2"
                        :preview-src-list="[imageUrl]"
                        :initial-index="4"
                        hide-on-click-modal
                        fit="cover"
                    />
                    <div v-if="!imageUrl" style="width: 100%">
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :show-file-list="false"
                            ref="uploadRef"
                            multiple
                            :limit="1"
                            :http-request="(file) => handleUploadImg(file)"
                        >
                            <div class="content">
                                <el-icon class="avatar-uploader-icon">
                                    <Plus />
                                </el-icon>
                                <div>上传图片</div>
                            </div>
                        </el-upload>
                    </div>
                </el-col>
                <el-col :span="10" class="home-mini-content-detail-setting">
                    <div v-if="imageUrl">
                        <el-button type="primary" @click="handleClearImage"
                            >修改图片</el-button
                        >
                    </div>
                    <el-radio-group v-model="selectedSize" class="ml-4">
                        <el-radio label="1" size="large">750 * 1334px</el-radio>
                        <!-- <el-radio label="2" size="large">600 * 600px</el-radio> -->
                    </el-radio-group>
                    <!-- <div class="home-mini-content-detail-setting-mini">
                        <span>选择小程序：</span>
                        <el-select v-model="selectMini" class="m-2" placeholder="请选择" size="large">
                            <el-option v-for="item in miniList" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div> -->
                    <div class="home-mini-content-detail-setting-desc">
                        <div>温馨提示：</div>
                        <div>
                            图片问题：尺寸需要与选择的尺寸大小保持一致，大小不能超过5MB
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" class="home-mini-content-detail-preview">
                    <div
                        class="home-mini-content-detail-preview-item"
                        v-if="miniQRcode"
                    >
                        <div class="title">预览二维码</div>
                        <el-image
                            :src="miniQRcode"
                            class="avatar"
                            :zoom-rate="1.2"
                            :preview-src-list="[miniQRcode]"
                            :initial-index="4"
                            hide-on-click-modal
                            fit="cover"
                        />
                    </div>
                    <div class="home-mini-content-detail-preview-item text">
                        <div class="title">
                            {{
                                miniQRcode
                                    ? "点击图片可放大查看二维码"
                                    : "小程序待发布"
                            }}
                        </div>
                        <!-- <div class="content">/index/hxxxscsfds</div>
                        <div class="copy">复制</div> -->
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="home-mini-content-btn" v-if="orgId && isAuth">
            <el-button v-if="imageUrl" @click="handleClearConfig"
                >修改图片</el-button
            >
            <el-button type="primary" @click="handleSaveMiniConfig"
                >保存并复制路径</el-button
            >
        </div>
        <el-result
            :sub-title="
                !isAuth
                    ? '该小程序暂未授权'
                    : '选择上方小程序可配置小程序展示内容'
            "
            v-if="!orgId || (orgId && !isAuth)"
        >
            <template #icon>
                <el-image
                    style="width: 200px"
                    src="https://cdn.auoktalk.com/cdn/kid-mp/images/note-student-work.png"
                />
            </template>
            <template
                #extra
                v-if="
                    !userInfo?.organizations ||
                    (userInfo?.organizations &&
                        userInfo?.organizations.length === 0) ||
                    !isAuth
                "
            >
                <el-button type="primary" @click="handleSkipSetting">{{
                    !isAuth && orgId
                        ? "请先到通用设置授权小程序"
                        : "请先到通用设置创建小程序吧"
                }}</el-button>
            </template>
        </el-result>
        <el-row class="home-empty" v-if="isAwaitMini">
            <el-col>小程序授权状态查询中</el-col>
            <el-col class="empty_bg">
                <img class="" src="../../assets/images/empty.png" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { reactive, ref } from "vue";
import Uploader from "@/utils/upload/ossupload";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import {
    orgContentSave,
    orgAuthStatus,
    orgContentList,
    createMpQRcode,
} from "@/services/modules/organization";
import { getUserInfo } from "@/services/modules/user";
import { formatDate, copyText } from "@/utils/utils";

export default {
    setup() {
        const route = new useRoute();
        const router = new useRouter();
        const selected = ref("1");
        const selectedSize = ref("1");
        const imageUrl = ref("");
        const selectMini = ref("");
        const miniList = reactive([]);
        const uploadRef = ref();
        const isCreateMini = ref(false);
        const isAwaitMini = ref(false);
        const orgId = ref(route?.query?.orgId || "");
        const userInfo = reactive({});
        const isAuth = ref(true);
        const currentOrgContentId = ref("");
        const miniQRcode = ref("");

        const handleUploadImg = (params) => {
            Uploader.put(
                params.file,
                params.file.type,
                async (url) => {
                    console.log("----", url);
                    imageUrl.value = url;
                    if (uploadRef.value) {
                        uploadRef.value.clearFiles();
                    }
                    ElMessage.success("上传成功");
                },
                (err) => {
                    console.log("----", err);
                    ElMessage.error("上传失败");
                }
            );
        };

        const handleClearImage = () => {
            imageUrl.value = "";
        };

        const handleCreate = () => {
            window.open("https://mp.weixin.qq.com/");
        };

        const userInfoInit = async () => {
            getUserInfo()
                .then((res) => {
                    let info = res.data;
                    if (info) {
                        info.organizations = info.organizations.map((item) => {
                            return {
                                ...item,
                                appId:
                                    item.authorizer &&
                                    item.authorizer.length > 0
                                        ? item.authorizer[0].appId
                                        : "",
                                date: formatDate(
                                    item.createDate,
                                    "yyyy-MM-dd hh:mm:ss"
                                ),
                            };
                        });
                        if (!orgId.value && info.organizations.length > 0) {
                            orgId.value = info.organizations[0].id;
                            selectOrgChange(info.organizations[0].id);
                        }
                    }
                    Object.assign(userInfo, res.data);
                    console.log("userInfo", userInfo);
                })
                .catch((err) => {
                    Object.assign(
                        userInfo,
                        localStorage.getItem("userInfo")
                            ? JSON.parse(localStorage.getItem("userInfo"))
                            : {}
                    );
                    console.log("userInfo", userInfo);
                });
        };

        userInfoInit();

        const selectOrgChange = (value) => {
            orgId.value = value;
            orgOneAuthStatus();
            orgContentInit();
        };

        const handleSaveMiniConfig = async () => {
            if (!imageUrl.value) {
                return ElMessage.error("请先上传海报图片");
            }

            const idParams = currentOrgContentId.value
                ? { id: currentOrgContentId.value }
                : {};
            let params = {
                orgId: orgId.value,
                title: userInfo.organizations.find(
                    (item) => item.id == orgId.value
                ).title,
                imgUrl: imageUrl.value,
                // ...idParams,
            };

            const res = await orgContentSave(params);
            if (res.code == 1) {
                ElMessage.success("保存成功");
                currentOrgContentId.value = res.data.id;
                copyText(`pages/index/index?scene=${res.data.id}`);
                return;
            }

            ElMessage.error(res.msg || res.message || "保存失败");
        };

        const handleClearConfig = () => {
            imageUrl.value = "";
            if (uploadRef.value) {
                uploadRef.value.clearFiles();
            }
        };

        const orgContentInit = async () => {
            let params = {
                orgId: orgId.value,
            };
            const res = await orgContentList(params);
            if (res.code == 1) {
                if (res.data.length > 0) {
                    imageUrl.value = res.data[0].imgUrl;
                    currentOrgContentId.value = res.data[0].id;
                    QRcodeInit();
                } else {
                    imageUrl.value = "";
                    currentOrgContentId.value = "";
                    QRcodeInit();
                }
            }
        };

        const QRcodeInit = async () => {
            console.log("userInfo", userInfo);
            const currentOrg = userInfo.organizations.find(
                (item) => item.id == orgId.value
            );
            console.log("currentOrg", currentOrg);
            if (!currentOrg && currentOrg.authorizer.length === 0) return;
            let params = {
                appId: currentOrg.authorizer[0].appId,
                appName: currentOrg.title,
                param: `${currentOrgContentId.value}`,
                path: "pages/index/index",
                version: 0,
            };

            const res = await createMpQRcode(params);
            if (res.data) {
                miniQRcode.value = `data:image/png;base64,${res.data}`;
            }
        };

        const orgOneAuthStatus = async () => {
            const params = {
                orgId: orgId.value,
            };

            const res = await orgAuthStatus(params);
            isAuth.value = res.data;
        };

        const handleSkipSetting = () => {
            router.push("/setting");
        };

        if (orgId.value) {
            orgOneAuthStatus();
            orgContentInit();
        }

        return {
            selected,
            imageUrl,
            selectedSize,
            selectMini,
            miniList,
            handleUploadImg,
            uploadRef,
            isCreateMini,
            isAwaitMini,
            handleClearImage,
            handleCreate,
            userInfo,
            selectOrgChange,
            orgId,
            handleClearConfig,
            handleSaveMiniConfig,
            isAuth,
            handleSkipSetting,
            miniQRcode,
        };
    },
};
</script>

<style lang="scss" scoped>
.home {
    background: #fff;
    border-radius: 5px;
    width: 100%;

    &-mini-create {
        padding: 30px;

        .card_bg {
            background-color: #f7f8fa;
            padding: 10px;
        }

        .align_center {
            text-align: center;
        }

        .title {
            font-weight: 700;
            font-size: 16px;
        }

        .des {
            font-size: 14px;
            color: #737171;
            margin: 30px 0 50px 0;
        }

        .btn {
            width: 200px;
            background-color: #3572ff;
        }
    }

    &-mini-content {
        border: 1px solid #e0e6ed;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 101px;

        &-select {
            width: 100%;
            padding: 0 30px;
            height: 43px;
            border-bottom: 1px solid #e0e6ed;
            box-sizing: border-box;
        }

        &-detail {
            width: 100%;

            &-upload {
                width: 100% !important;
                margin: 20px 0;
                display: flex;
                justify-content: center;
                position: relative;
                margin-left: 20px;
                box-sizing: border-box;

                &:hover {
                    .close {
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .avatar {
                    width: 100%;
                    height: 100%;
                }

                .close {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    z-index: 9;
                    display: none;
                    cursor: pointer;

                    background: rgba($color: #000000, $alpha: 0.5);
                    color: #fff;
                    transition: all 0.5s;

                    .el-icon {
                        font-size: 20px;
                    }
                }

                .avatar-uploader {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #e0e6ed;

                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .el-icon {
                            font-size: 30px;
                            font-weight: bold;
                            color: #999;
                        }

                        div {
                            font-size: 18px;
                            margin-top: 5px;
                            color: #999;
                        }
                    }
                }
            }

            &-setting {
                padding: 20px;

                &-mini {
                    margin: 28px 0;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 14px;
                        font-weight: bold;
                        color: #505968;
                    }
                }

                &-desc {
                    width: 100%;
                    background: #f6f8fc;
                    border-radius: 2px;
                    padding: 18px;
                    box-sizing: border-box;

                    div {
                        color: #738197;
                        font-size: 12px;

                        &:nth-child(2) {
                            margin-top: 16px;
                        }
                    }
                }
            }

            &-preview {
                width: 100%;
                border-left: 1px solid #e0e6ed;
                padding: 20px;

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    .copy {
                        color: #3572ff;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 10px;
                    }
                }

                &-item {
                    text-align: center;


                    .title {
                        color: #111;
                        font-size: 14px;
                    }

                    .el-image {
                        width: 240px;
                        height: 240px;
                        background: red;
                        display: block;
                        margin: 20px auto;
                    }
                }
            }
        }

        &-btn {
            position: fixed;
            bottom: 0;
            height: 100px;
            width: 100%;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
        }
    }

    .home-empty {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .empty_bg {
            margin-top: 30px;
            width: 140px;

            & > img {
                width: 140px;
            }
        }
    }
}
</style>